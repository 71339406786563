import React, { useState, useEffect } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/Section/Section';
import Headline from '../../components/Headline/Headline';
import FlipperCard from '../../components/FlipperCard/FlipperCard';

import Aos from 'aos';
import 'aos/dist/aos.css';

import img_leistung_11 from '../../images/leistungen/img11.jpg';
import img_leistung_16 from '../../images/leistungen/img16.jpg';

const LeistungenPage = () => {

  useEffect(() => {
    Aos.init({duration: 2000 });
  }, []);

  return(
  <Layout headerWhite>
    <SEO title="Leistungen | Dr. Kostka - integrative Medizin und Ästhetik" description="Dr. K. Kostka bietet integrative Medizin und Ästhetik mitten in Bochum. Moderne Praxis ✓ Top Ausstattung ✓ Vereinbaren Sie jetzt einen Termin ✓" />
    {/*<div className="greenContainer">
      <ul>
        <li><Link to="/leistungen/aesthetik">Ästhetik</Link></li>
        <li><Link to="/leistungen/schmerz">Schmerz</Link></li>
        <li className="active"><Link to="/leistungen/ernaehrung">Ernährung</Link></li>
      </ul>
    </div>*/}
    <Section>
      <Container>
        <Headline h1>Ernährung</Headline>
        {/*<Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Ernährungsberatung -
                {' '}
                <i>Beratung und Therapie</i>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
              Viele, die eine Ernährungsberatung wünschen, haben bereits etliche erfolglose Diäten hinter sich.
              <br/>
              <br/>
              Die Formel ist zwar denkbar einfach: Übersteigt die Kalorienzufuhr den Energieverbrauch, nehmen wir unweigerlich zu.
              Obwohl wir das alle wissen, ist der Weg zu einer für Sie „gesunden“ Ernährung und anhaltenden  Gewichtsreduktion  steinern, vergleichbar mit dem Wunsch, daß Rauchen aufzugeben.
              <br/>
              <br/>
              Mein Behandlungsprogramm beinhaltet dementsprechend nicht nur eine umfassende Schulung, in der Ihnen die Grundlagen der Ernährung vermittelt werden.
              Entscheidend ist ein auf Ihren persönlichen Lebensstil individuell abgestimmter Ernährungs- und Bewegungsplan, der auch ihre Vorerkrankungen berücksichtigt. 
              Es wird nicht nur darauf ankommen, was sie essen, sondern welche Möglichkeiten bestehen, auch ihren Aktivitätslevel zu steigern.
              <br/>
              <br/>
              Der Beginn ist wissenschaftlich fundiertes, standardisiertes ernährungsmedizinisches Staging: Ernährungsprotokoll, Bestimmung der Körperzusammensetzung  (Fett/Muskel/Wasser/Knochen) mittels Bioimpedanzanalyse und Ermittlung von Energie- und Nährstoffbedarf.
              <br/>
              <br/>
              Wir werden realistische und realisierbare Ziele besprechen.
              <br/>
              <br/>
              Ihr Ernährungsplan wird sich daran orientieren.
              <br/>
              <br/>
              Nach jeweils 4  Wochen erfolgen 2 weitere Beratungseinheiten und und nach 6 Monaten eine erneute Statusaufnahme mittels Bio-Impedanz-Analyse.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          </Accordion>
        <Accordion>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>

              Mikronährstofftherapie (metabolic tuning) -
                {' '}
                <i>Medizin der richtigen Nährstoffe</i>

              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
              Sprechen wir von Ernährung, so meinen wir im allgemeinen Fette, Kohlenhydrate und Eiweiße- die Makronährstoffe. Unser Stoffwechsel benötigt aber auch sogenannte „Vitalstoffe“- die Mikronährstoffe- wie Vitamine, Spurenelemente und Mineralstoffe, ohne die unser Organismus auf zellulärer Ebene nicht funktionieren würde.
              <br/>
              <br/>
              Ständiger Stress, chronische Erschöpfung, ein zu viel an Sport oder Erkrankungen wie z.B die Fibromyalgie können zu Fehlfunktionen führen. Hier ist der Einsatz von Mikronährstoffen sinnvoll und zeigt gute Behandlungserfolge. Wir setzen unsere Aufbauinfusionen einmal wöchentlich über einen Zeitraum von 4 Wochen ein.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>*/}
      </Container>
      <Container>
        <Row className="justify-content-md-center" data-aos="fade-up">
          <Col md={9}>
          <FlipperCard frontImage={img_leistung_11} title="Ernährungsberatung" subtitle="Beratung und Therapie" flipHint={true} smallH2={true}>
          <p>
              Viele, die eine Ernährungsberatung wünschen, haben bereits etliche erfolglose Diäten hinter sich.
              <br/>
              <br/>
              Die Formel ist zwar denkbar einfach: Übersteigt die Kalorienzufuhr den Energieverbrauch, nehmen wir unweigerlich zu.
              Obwohl wir das alle wissen, ist der Weg zu einer für Sie „gesunden“ Ernährung und anhaltenden  Gewichtsreduktion  steinern, vergleichbar mit dem Wunsch, daß Rauchen aufzugeben.
              <br/>
              <br/>
              <Link className="whiteLinkText" to="/behandlung/ernaehrungsberatung">Weiter lesen ...</Link>
              </p>
          </FlipperCard>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-md-center" data-aos="fade-up">
          <Col md={9}>
          <FlipperCard frontImage={img_leistung_16} title="Mikronährstofftherapie (metabolic tuning)" subtitle="Medizin der richtigen Nährstoffe" flipHint={false} smallH2={true}>
            <p>
              Sprechen wir von Ernährung, so meinen wir im allgemeinen Fette, Kohlenhydrate und Eiweiße- die Makronährstoffe. Unser Stoffwechsel benötigt aber auch sogenannte „Vitalstoffe“- die Mikronährstoffe- wie Vitamine, Spurenelemente und Mineralstoffe, ohne die unser Organismus auf zellulärer Ebene nicht funktionieren würde.
              <br/>
              <br/>
              <Link className="whiteLinkText" to="/behandlung/mikronaehrstofftherapie">Weiter lesen ...</Link>
            </p>
          </FlipperCard>
          </Col>
        </Row>
      </Container>
    </Section>
  </Layout>
  )
      };

export default LeistungenPage;
